import React from 'react'
import { LayoutA } from "../layouts";
import {Classes, H3} from '@blueprintjs/core'

class Pricing extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="UpdatesPage">
          <div className="UpdatesPage-todolist">
            <div className="bp3-card todo-sticky">
              <H3>Our TODO List...</H3>
              <div className="features">
                <div className="grid-row">
                  <div className="grid-item">
                    <div><span className="pt-icon-standard pt-icon-square"/>Upcoming feature...</div>
                    <div><span className="pt-icon-standard pt-icon-tick"/>Finsihed Feature!</div>
                    <div><span className="pt-icon-standard pt-icon-tick"/>Finsihed Feature!</div>
                    <div><span className="pt-icon-standard pt-icon-tick"/>Finsihed Feature!</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="UpdatesPage-updates">
            <UpdateItem version={"v1.3.2"} date={"July 31st, 2018"}>
              <>
                <p>Another round of sweet, sweet features.</p>
                <p><strong>What's new in this version:</strong></p>
                <ol>
                  <li>Something great!
                    <ul>
                      <li>Details of this great thing..</li>
                      <li><a href="/pricing">Check out more details here.</a></li>
                    </ul>
                  </li>
                  <li><a href="/doc/project-groups">Something else great!</a>
                    <ul>
                      <p><img
                        src=""/>
                      </p>
                      <p><img
                        src=""/>
                      </p>
                    </ul>
                  </li>
                </ol>
                <p><strong>Quality of Life improvements:</strong></p>
                <ol>
                  <li>There is now a Wide Screen Mode (we love this feature!)</li>
                  <p><img
                    src=""/>
                  </p>
                  <li>Performance increased!</li>
                </ol>
                <p><strong>Bug fixes:</strong></p>
                <ol>
                  <li>Fixed a transient issue .</li>
                </ol>
              </>
            </UpdateItem>
          </div>
        </div>
      </LayoutA>
    )
  }

}

class UpdateItem extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { version, date } = this.props;

    return (
      <div className={"UpdatesPage-updateitem " + Classes.RUNNING_TEXT}>
        <div className="grid-row">
          <a name={version}></a>
          <div className="grid-item update-item-title">
            <h2 className="">{version}</h2>
            <p className="date">{date}</p>
          </div>
          <div className="grid-item-4 ">
            <div className="markdown">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }

}


export default Pricing;
